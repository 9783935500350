<template>
  <div class="etc increment-wrap">
    <div class="line" />
    <h2>汽车及后市场服务</h2>
    <div class="img">
      <!-- <div class="title">汽车及后市场服务</div> -->
      <div style="padding-top:70px; text-align:center;"><img src="./../../assets/img/7.jpg" alt=""></div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
@import "./../../assets/scss/base.scss";
</style>
